import "./scss/plugins/_swiper.scss";

import Swiper, { Navigation, Pagination } from "swiper";

const sliderGalleries = document.querySelectorAll("[data-swiper-gallery]");
if (sliderGalleries.length) {
  sliderGalleries.forEach((gallery) => {
    const slidesPerView = Number(gallery.getAttribute("data-slides-per-view"));
    let slidesPerViewTablet = 1;
    if (slidesPerView >= 3) {
      slidesPerViewTablet = Math.round(slidesPerView / 2);
    }
    const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1;
    const sliderGallery = new Swiper(gallery, {
      modules: [Navigation, Pagination],
      slidesPerView: slidesPerViewMobile,
      spaceBetween: 14,
      autoHeight: true,
      loop: false,
      pagination: {
        el: "[data-swiper-pagination]",
        type: "fraction",
      },
      navigation: {
        nextEl: "[data-swiper-button-next]",
        prevEl: "[data-swiper-button-prev]",
      },
      breakpoints: {
        667: {
          slidesPerView: slidesPerViewTablet,
        },
        992: {
          slidesPerView: slidesPerView,
        },
      },
    });
  });
}

const sliderFeaturedPosts = new Swiper("[data-swiper-featured-posts]", {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: 14,
  autoHeight: true,
  loop: true,
  pagination: {
    el: "[data-swiper-pagination]",
    type: "fraction",
  },
  navigation: {
    nextEl: "[data-swiper-button-next]",
    prevEl: "[data-swiper-button-prev]",
  },
});

const sliderPosts = new Swiper("[data-swiper-posts]", {
  modules: [Navigation, Pagination],
  slidesPerView: 1.25,
  spaceBetween: 26,
  autoHeight: true,
  loop: false,
  pagination: {
    el: "[data-swiper-pagination]",
    type: "fraction",
  },
  navigation: {
    nextEl: "[data-swiper-button-next]",
    prevEl: "[data-swiper-button-prev]",
  },
  breakpoints: {
    667: {
      slidesPerView: 2.5,
    },
    992: {
      slidesPerView: 3,
    },
  },
});
